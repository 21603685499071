<template>
  <div class="change_bg" >
    <div class="modal_bg" :class="{'active' : isActive_m}"  @click="clouseM()">
      <div class="modal_content" v-if="dataId">
        <div class="m_card">
            <div class="m_card_main show_card_id_modal">
              <div class="m_body_card_main">
                <div class="m_card_img">
                  <img :src="dataId.image" alt="">
                </div>
                <div class="m_card_text">
                  <div class="m_card_title show_card_id">{{dataId.leader}}</div>
                  <div class="m_card_description">{{dataId.task}}</div>
                  <div class="m_card_data">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.60659 13.3649C9.50659 14.2279 10.5616 14.9299 11.7276 15.4279L12.2466 15.6489L13.8416 14.0289C14.2376 13.6269 14.8106 13.4529 15.3636 13.5689L19.2276 14.3779C19.8816 14.5169 20.3486 15.0939 20.3486 15.7619V18.5439C20.3486 19.5389 19.5426 20.3449 18.5476 20.3449H17.6926C13.7586 20.3449 9.87659 19.0419 6.94059 16.4239C6.69859 16.2089 6.46359 15.9859 6.23459 15.7579L6.27159 15.7949C6.04259 15.5659 5.82059 15.3309 5.60559 15.0889C2.98659 12.1539 1.68359 8.27188 1.68359 4.33788V3.48288C1.68359 2.48788 2.48959 1.68188 3.48459 1.68188H6.26659C6.93459 1.68188 7.51159 2.14888 7.65059 2.80288L8.46059 6.66688C8.57559 7.21888 8.40259 7.79288 8.00059 8.18888L6.38059 9.78389L6.60159 10.3029C7.09959 11.4679 7.74259 12.4649 8.60659 13.3649Z" stroke="#B3B3B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{dataId.phone_number}}
                  </div>
                  <div class="m_card_data">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 6.99997V11C15 12.473 16.194 13.667 17.667 13.667C19.14 13.667 20.334 12.473 20.334 11V11.001C20.334 5.84597 16.155 1.66797 11.001 1.66797C5.84697 1.66797 1.66797 5.84697 1.66797 11.001C1.66797 16.155 5.84497 20.335 11 20.335C13.577 20.335 15.911 19.29 17.6 17.601M15 11.001C15 13.2101 13.2091 15.001 11 15.001C8.79083 15.001 6.99997 13.2101 6.99997 11.001C6.99997 8.79183 8.79083 7.00097 11 7.00097C13.2091 7.00097 15 8.79183 15 11.001Z" stroke="#B3B3B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{dataId.email}}
                  </div>
                  <div class="m_card_data">

                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.99995 5.13598V8.99898L11.667 10.239M16.998 8.99898C16.998 13.4161 13.4171 16.997 8.99995 16.997C4.58278 16.997 1.00195 13.4161 1.00195 8.99898C1.00195 4.5818 4.58278 1.00098 8.99995 1.00098C13.4171 1.00098 16.998 4.5818 16.998 8.99898Z" stroke="#B3B3B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{dataId.previous_work}}
                  </div>
                </div>
              </div>
              <div>
                <button class="exit_modal" @click="clouseM()">
                  {{$store.state.language.lang[73]}}
                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.03125 0.861328L15.2526 15.08M1.03125 15.08L15.2526 0.861328" stroke="#1DBB95" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
              </div>
            </div>
          <div class="m_card_bottom">
            <div class="m_b">
                <div class="m_b_title">{{$store.state.language.lang[40]}}</div>
                <div class="m_b_data">{{dataId.task}}</div>
            </div>
            <div class="m_b">
              <div class="m_b_title">{{$store.state.language.lang[42]}}</div>
              <div class="m_b_data">{{new Date(dataId.birthdate).toLocaleDateString()}} {{$store.state.language.lang[4]}}</div>
            </div>
            <div class="m_b">
              <div class="m_b_title">{{$store.state.language.lang[43]}}</div>
              <div class="m_b_data">{{dataId.education}}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">{{$store.state.language.lang[1]}}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z" fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{$store.state.language.lang[10]}}
            </router-link>
          </div>
        </div>
        <div class="row" >
          <div class="col-md-3">

            <div class="m_sidebar">
              <div class="m_sidebar_title">
                {{$store.state.language.lang[1]}}
              </div>
              <div class="m_sidebar_link">
                <router-link to="/agency/management" class="menu_drops">
                  {{$store.state.language.lang[10]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/central/office" class="menu_drops">
                  {{$store.state.language.lang[9]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/structure" class="menu_drops">
                  {{$store.state.language.lang[11]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/council" class="menu_drops">
                  {{$store.state.language.lang[12]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/territorial" class="menu_drops">
                  {{$store.state.language.lang[13]}}
                </router-link>
              </div>

              <div class="m_sidebar_link">
                <router-link to="/regulations" class="menu_drops">
                  {{$store.state.language.lang[15]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/organization" class="menu_drops">
                  {{$store.state.language.lang[16]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/activity" class="menu_drops">
                  {{$store.state.language.lang[17]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/museum" class="menu_drops">
                  {{$store.state.language.lang[18]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/vacancy" class="menu_drops">
                  {{$store.state.language.lang[19]}}
                </router-link>
              </div>

            </div>
          </div>
          <div class="col-xl-9 col-12" v-if="$store.state.management1">
            <div class="m_body_title">
              <h2>{{ $store.state.language.lang[10] }}</h2>
            </div>
            <MediaSide :name="$store.state.language.lang[10]"></MediaSide>
            <div class="m_body_card " v-for="(item,index) in $store.state.management1.managements" :key="index">
              <div class="m_body_card_main">
                <div class="m_card_img">
                  <img :src="item.image" alt="">
                </div>
                <div class="m_card_text">
                  <div class="m_card_title">{{item.title}}</div>
                  <div class="m_card_description">{{item.leader}}</div>
                  <div class="m_card_data">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.60659 13.3649C9.50659 14.2279 10.5616 14.9299 11.7276 15.4279L12.2466 15.6489L13.8416 14.0289C14.2376 13.6269 14.8106 13.4529 15.3636 13.5689L19.2276 14.3779C19.8816 14.5169 20.3486 15.0939 20.3486 15.7619V18.5439C20.3486 19.5389 19.5426 20.3449 18.5476 20.3449H17.6926C13.7586 20.3449 9.87659 19.0419 6.94059 16.4239C6.69859 16.2089 6.46359 15.9859 6.23459 15.7579L6.27159 15.7949C6.04259 15.5659 5.82059 15.3309 5.60559 15.0889C2.98659 12.1539 1.68359 8.27188 1.68359 4.33788V3.48288C1.68359 2.48788 2.48959 1.68188 3.48459 1.68188H6.26659C6.93459 1.68188 7.51159 2.14888 7.65059 2.80288L8.46059 6.66688C8.57559 7.21888 8.40259 7.79288 8.00059 8.18888L6.38059 9.78389L6.60159 10.3029C7.09959 11.4679 7.74259 12.4649 8.60659 13.3649Z" stroke="#B3B3B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{item.phone_number}}
                  </div>
                  <div class="m_card_data">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 6.99997V11C15 12.473 16.194 13.667 17.667 13.667C19.14 13.667 20.334 12.473 20.334 11V11.001C20.334 5.84597 16.155 1.66797 11.001 1.66797C5.84697 1.66797 1.66797 5.84697 1.66797 11.001C1.66797 16.155 5.84497 20.335 11 20.335C13.577 20.335 15.911 19.29 17.6 17.601M15 11.001C15 13.2101 13.2091 15.001 11 15.001C8.79083 15.001 6.99997 13.2101 6.99997 11.001C6.99997 8.79183 8.79083 7.00097 11 7.00097C13.2091 7.00097 15 8.79183 15 11.001Z" stroke="#B3B3B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{item.email}}
                  </div>
                  <div class="m_card_data">

                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.99995 5.13598V8.99898L11.667 10.239M16.998 8.99898C16.998 13.4161 13.4171 16.997 8.99995 16.997C4.58278 16.997 1.00195 13.4161 1.00195 8.99898C1.00195 4.5818 4.58278 1.00098 8.99995 1.00098C13.4171 1.00098 16.998 4.5818 16.998 8.99898Z" stroke="#B3B3B3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{item.previous_work}}
                  </div>
                </div>
              </div>
              <div>
                <div class="m_body_pattern">
                  <img src="../../assets/img/pattern23.png" alt="">
                  <div class="m_more">
                    <button @click="isActive_m=true;myFunck(item)">
                      {{$store.state.language.lang[25]}}
                      <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.810547 7.00097V1.66797M0.810547 1.66797H6.14355M0.810547 1.66797L7.47655 8.33397M0.810547 14.999V20.332M0.810547 20.332H6.14355M0.810547 20.332L7.47655 13.666M19.4765 7.00097V1.66797M19.4765 1.66797H14.1435M19.4765 1.66797L12.8105 8.33397M19.4765 14.999V20.332M19.4765 20.332H14.1435M19.4765 20.332L12.8105 13.666" stroke="#1DBB95" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../Header/Navbar";
import Footer from "../Header/Footer";
import MediaSide from '../MediaSide'
export default {
  components: {
    MediaSide,
    Footer,
    Navbar
  },
  data(){
    return{
      isActive_m:false,
      dataId:{}
    }
  },
  mounted() {
    this.$store.dispatch('management2')
  },
  methods:{

    clouseM(){
      this.isActive_m=false
      if(this.isActive_m==false){
        document.body.classList.remove('open_modal')
      }
    },
    myFunck(item){
      this.dataId=item
      this.isActive_m=true
      if(this.isActive_m==true){
        document.body.classList.add('open_modal')
      }
    }
  }
}
</script>
